import { computed, Ref, ref, watch } from 'vue'
import { AsfObservableObj, AsfObserver, MediaMaxBreakpoints } from '@ui/types'

interface MediaObservableObj extends AsfObservableObj {
  isMediaMatch: Ref<boolean>
}

const observer: AsfObserver<MediaObservableObj> = {}

const mapMediaObserver = (mediaMax = MediaMaxBreakpoints.MD) => {
  if (process.server) {
    return {
      isMediaMatch: () => MediaMaxBreakpoints.SM >= mediaMax,
      dispose() {}
    }
  }
  function onMediaMatch(e: MediaQueryListEvent) {
    const observerRecord = observer[mediaMax]

    if (observerRecord) {
      observerRecord.isMediaMatch.value = e.matches
    }
  }
  const isMediaMatch = ref<boolean>(MediaMaxBreakpoints.SM >= mediaMax)
  let unwatch: () => void | undefined
  const timer = setTimeout(() => {
    if (!observer[mediaMax]) {
      const mqList = window.matchMedia(`(max-width: ${mediaMax}px)`)

      observer[mediaMax] = {
        listener: onMediaMatch,
        mqList,
        clients: 0,
        isMediaMatch: ref(mqList.matches)
      } as MediaObservableObj

      mqList.addEventListener('change', onMediaMatch)
    }
    const observerRecord = observer[mediaMax]

    if (observerRecord) {
      observerRecord.clients = (observerRecord.clients || 0) + 1

      isMediaMatch.value = observerRecord.isMediaMatch.value
      unwatch = watch(observerRecord.isMediaMatch, () => {
        isMediaMatch.value = observerRecord.isMediaMatch.value
      })
    }
  })

  return {
    isMediaMatch: () => isMediaMatch.value,
    dispose() {
      if (unwatch) {
        unwatch()
        const observerRecord = observer[mediaMax]
        if (observerRecord) {
          observerRecord.clients = (observerRecord.clients || 0) - 1
          if (observerRecord.clients === 0) {
            observerRecord.mqList.removeEventListener('change', observerRecord.listener)
            delete observer[mediaMax]
          }
        }
      } else {
        clearTimeout(timer)
      }
    }
  }
}

export const useMediaQuery = (mediaMax = MediaMaxBreakpoints.MD) => ({
  isMediaMatch: computed(mapMediaObserver(mediaMax).isMediaMatch)
})
