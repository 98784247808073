<script setup lang="ts">
import { AsfLinkProps } from '@ui/types'
import { NO_NOOPENER, NO_REFERRER } from './Link.utils'

const props = withDefaults(defineProps<AsfLinkProps>(), {
  link: '',
  prevent: false,
  isAnchor: false
})
const attrs = useAttrs()

const instance = useCurrentInstance()
const { getURL } = useURL()

const isExternal = computed(
  () =>
    typeof props.link === 'string' &&
    (props.link.search(/(^\/|^#)/g) === -1 || /tel/.test(props.link) || /mailto/.test(props.link))
)
const isAnchorLink = computed(() => props.isAnchor || (typeof props.link === 'string' && props.link[0] === '#'))
const isNativeLinkTag = computed(() => isAnchorLink.value || isExternal.value || !instance.$router)

const url = computed(() => {
  if (props.prevent) return undefined
  if (isNativeLinkTag.value) return { href: props.link }
  if (typeof props.link === 'string') {
    return { to: props.link }
  } else if (props.link) {
    return { to: getURL(props.link) }
  } else {
    return ''
  }
})

const relAttribute = computed(() => {
  const target = attrs.target as string
  if (!target || !target.includes('_blank')) return props.rel || undefined

  return `${props.rel || ''} ${NO_REFERRER} ${NO_NOOPENER}`.trim()
})

const component = computed(() => {
  if (props.prevent) {
    return 'span'
  }

  // check updates for nuxt-link/router-link, has problem with @click.[native|prevent|etc]
  // https://github.com/nuxt/nuxt.js/issues/10593
  return isNativeLinkTag.value ? 'a' : resolveComponent('NuxtLink')
})
</script>
<template>
  <component :is="component" class="asf-link" v-bind="{ ...$attrs, ...url }" :rel="relAttribute">
    <slot />
  </component>
</template>
