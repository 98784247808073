export const localeCodes = ["us","ca","gb"]

export const localeMessages = {
  "us": [{ key: "../lang/en-US-default.mjs", load: () => import("../lang/en-US-default.mjs" /* webpackChunkName: "lang_lang_lang_en_US_default_mjs" */) }],
  "ca": [{ key: "../lang/en-CA.mjs", load: () => import("../lang/en-CA.mjs" /* webpackChunkName: "lang_lang_lang_en_CA_mjs" */) }],
  "gb": [{ key: "../lang/en-GB.mjs", load: () => import("../lang/en-GB.mjs" /* webpackChunkName: "lang_lang_lang_en_GB_mjs" */) }],
}

export const resolveNuxtI18nOptions = async (context) => {
  const nuxtI18nOptions = Object({})
  nuxtI18nOptions.experimental = Object({"jsTsFormatResource":false})
  nuxtI18nOptions.bundle = Object({"compositionOnly":true,"runtimeOnly":false,"fullInstall":true})
  nuxtI18nOptions.compilation = Object({"jit":true,"strictMessage":true,"escapeHtml":false})
  nuxtI18nOptions.customBlocks = Object({"defaultSFCLang":"json","globalSFCScope":false})
 const vueI18nConfigLoader = async (loader) => {
            const config = await loader().then(r => r.default || r)
            return typeof config === 'object'
              ? config
              : typeof config === 'function'
                ? await config()
                : {}
          }
  const vueI18n = await vueI18nConfigLoader((() => import("../vue-i18n.options.ts?hash=c82f3652&config=1" /* webpackChunkName: "__vue_i18n_options_ts_c82f3652" */)))
  nuxtI18nOptions.vueI18n = vueI18n
  nuxtI18nOptions.vueI18n.messages ??= {}
  const deepCopy = (src, des, predicate) => {
            for (const key in src) {
              if (typeof src[key] === 'object') {
                if (!typeof des[key] === 'object') des[key] = {}
                deepCopy(src[key], des[key], predicate)
              } else {
                if (predicate) {
                  if (predicate(src[key], des[key])) {
                    des[key] = src[key]
                  }
                } else {
                  des[key] = src[key]
                }
              }
            }
          }
          const mergeMessages = async (messages, loader) => {
            const layerConfig = await vueI18nConfigLoader(loader)
            const vueI18n = layerConfig || {}
            const layerMessages = vueI18n.messages || {}
            for (const [locale, message] of Object.entries(layerMessages)) {
              messages[locale] ??= {}
              deepCopy(message, messages[locale])
            }
          }
  nuxtI18nOptions.locales = [Object({"code":"us","domain":"http://localhost:3000","iso":"en-US","currency":"USD","int":Object({"scl":Object({"shopId":1001})}),"files":["lang/en-US-default.mjs"],"hashes":["d7c493a9"],"types":["static"]}),Object({"code":"ca","domain":"http://localhost:3000","iso":"en-CA","currency":"CAD","int":Object({"scl":Object({"shopId":1001})}),"files":["lang/en-CA.mjs"],"hashes":["542ec962"],"types":["static"]}),Object({"code":"gb","domain":"http://localhost:3000","iso":"en-GB","currency":"GBP","int":Object({"scl":Object({"shopId":1001})}),"files":["lang/en-GB.mjs"],"hashes":["3447de7f"],"types":["static"]})]
  nuxtI18nOptions.defaultLocale = "us"
  nuxtI18nOptions.defaultDirection = "ltr"
  nuxtI18nOptions.routesNameSeparator = "___"
  nuxtI18nOptions.trailingSlash = false
  nuxtI18nOptions.defaultLocaleRouteNameSuffix = "default"
  nuxtI18nOptions.strategy = "prefix_except_default"
  nuxtI18nOptions.lazy = true
  nuxtI18nOptions.langDir = "lang/"
  nuxtI18nOptions.rootRedirect = null
  nuxtI18nOptions.detectBrowserLanguage = Object({"alwaysRedirect":false,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"agsf-locale","cookieSecure":false,"fallbackLocale":"","redirectOn":"root","useCookie":true})
  nuxtI18nOptions.differentDomains = false
  nuxtI18nOptions.baseUrl = ""
  nuxtI18nOptions.dynamicRouteParams = false
  nuxtI18nOptions.customRoutes = "page"
  nuxtI18nOptions.pages = Object({})
  nuxtI18nOptions.skipSettingLocaleOnNavigate = false
  nuxtI18nOptions.types = "composition"
  nuxtI18nOptions.debug = false
  nuxtI18nOptions.parallelPlugin = false
  nuxtI18nOptions.i18nModules = []
  return nuxtI18nOptions
}

export const nuxtI18nOptionsDefault = Object({experimental: Object({"jsTsFormatResource":false}),bundle: Object({"compositionOnly":true,"runtimeOnly":false,"fullInstall":true}),compilation: Object({"jit":true,"strictMessage":true,"escapeHtml":false}),customBlocks: Object({"defaultSFCLang":"json","globalSFCScope":false}),vueI18n: "",locales: [],defaultLocale: "",defaultDirection: "ltr",routesNameSeparator: "___",trailingSlash: false,defaultLocaleRouteNameSuffix: "default",strategy: "prefix_except_default",lazy: false,langDir: null,rootRedirect: null,detectBrowserLanguage: Object({"alwaysRedirect":false,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","redirectOn":"root","useCookie":true}),differentDomains: false,baseUrl: "",dynamicRouteParams: false,customRoutes: "page",pages: Object({}),skipSettingLocaleOnNavigate: false,types: "composition",debug: false,parallelPlugin: false})

export const nuxtI18nInternalOptions = Object({__normalizedLocales: [Object({"code":"us","domain":"http://localhost:3000","iso":"en-US","currency":"USD","int":Object({"scl":Object({"shopId":1001})}),"files":["lang/en-US-default.mjs"],"hashes":["d7c493a9"],"types":["static"]}),Object({"code":"ca","domain":"http://localhost:3000","iso":"en-CA","currency":"CAD","int":Object({"scl":Object({"shopId":1001})}),"files":["lang/en-CA.mjs"],"hashes":["542ec962"],"types":["static"]}),Object({"code":"gb","domain":"http://localhost:3000","iso":"en-GB","currency":"GBP","int":Object({"scl":Object({"shopId":1001})}),"files":["lang/en-GB.mjs"],"hashes":["3447de7f"],"types":["static"]})]})
export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const isSSG = false
export const parallelPlugin = false
