export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"theme-color","content":"#3265e5"}],"link":[{"color":"#3265e5","href":"/favicons/safari-pinned-tab.svg","rel":"mask-icon"},{"href":"/favicons/apple-icon-180x180.png","rel":"apple-touch-icon"},{"href":"/favicons/apple-icon-152x152.png","rel":"apple-touch-icon","sizes":"152x152"},{"href":"/favicons/apple-icon-180x180.png","rel":"apple-touch-icon","sizes":"180x180"},{"href":"/favicons/favicon-16x16.png","rel":"icon","sizes":"16x16"},{"href":"/favicons/favicon-32x32.png","rel":"icon","sizes":"32x32"},{"href":"/favicons/manifest.json","rel":"manifest"},{"href":"/icons/icons-sprite.svg","rel":"prefetch","as":"image","type":"image/svg+xml"}],"style":[],"script":[],"noscript":[]}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const remoteComponentIslands = false

export const devPagesDir = null

export const devRootDir = null

export const vueAppRootContainer = '#__nuxt'