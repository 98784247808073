import { TranslateResult } from 'vue-i18n'
import { AsfIconProps } from '@ui/types'

export enum AsfKeyValue {
  TAB = 'Tab',
  ENTER = 'Enter',
  ESC = 'Escape',
  SPACE = ' ',
  PAGEUP = 'PageUp',
  PAGEDOWN = 'PageDown',
  END = 'End',
  HOME = 'Home',
  LEFT = 'ArrowLeft',
  UP = 'ArrowUp',
  RIGHT = 'ArrowRight',
  DOWN = 'ArrowDown'
}
export enum MediaMaxBreakpoints {
  SM = 767,
  MD = 1023,
  LG = 1366
}

export type AsfTransitions =
  | 'asf-bounce'
  | 'asf-expand'
  | 'asf-fade'
  | 'asf-slide-top'
  | 'asf-slide-bottom'
  | 'asf-slide-left'
  | 'asf-slide-right'
export type AsfVerticalPosition = 'top' | 'bottom'
export type AsfHorizontalPosition = 'left' | 'center' | 'right'
export type AsfPosition = AsfVerticalPosition | AsfHorizontalPosition
export type AsfCustomValidationRules = {
  [k: string]: any
}
export type AsfCustomValidationMessages = {
  [k: string]: TranslateResult
}
export type AsfFormElementProps = {
  autocomplete?: string
  caption?: TranslateResult | string
  disabled?: boolean
  form?: string
  id?: string
  label?: TranslateResult | string
  name?: TranslateResult | string
  required?: boolean
}
export type AsfFormFieldTypes =
  | 'date'
  | 'datetime-local'
  | 'email'
  | 'hidden'
  | 'month'
  | 'number'
  | 'password'
  | 'search'
  | 'tel'
  | 'text'
  | 'time'
  | 'url'
  | 'week'
  | 'checkbox'
  | 'radio'
export type AsfFormFieldType = Exclude<AsfFormFieldTypes, 'url' | 'search' | 'radio'> | 'select'
export type AsfInputTypes = Exclude<AsfFormFieldTypes, 'radio' | 'checkbox'>
export type AsfSetTimeout = ReturnType<typeof setTimeout>
export type AsfLocaleObject = {
  [k: string]: any
  iconName: AsfIconProps['name']
  id: string
  label: string
  value: string
  link?: string
}
export type AsfObserver<T> = {
  [key: number | string]: T
}
export interface AsfObservableObj {
  listener: (this: MediaQueryList, ev: MediaQueryListEvent) => void
  mqList: MediaQueryList
  clients?: number
}
