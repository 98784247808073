import { getCurrentInstance } from 'vue'

export function useCurrentInstance() {
  const instance = getCurrentInstance()

  if (!instance || !instance.proxy) {
    throw Error('instance is available only in vue component context ')
  }

  return instance.proxy
}
