<script setup lang="ts">
import { AsfErrorProps, AsfErrorTitleType, AsfListOfStatusCodes } from '@ui/types'

withDefaults(defineProps<AsfErrorProps>(), {
  isOnline: true
})

const { t } = useI18n()

const generateText = (code?: AsfListOfStatusCodes, type?: AsfErrorTitleType) => {
  switch (code) {
    case 404:
      return t(`errorPages.notFoundError.${type}`)
    case 440:
      return t(`errorPages.sessionExpiredError.${type}`)
    case 500:
      return t(`errorPages.internalServerError.${type}`)
    case 503:
      return t(`errorPages.maintenanceError.${type}`)
    case 504:
      return t(`errorPages.overloadError.${type}`)
    default:
      return t(`errorPages.defaultError.${type}`)
  }
}
</script>
<template>
  <div class="asf-error">
    <AsfWrapper tag="main" type="section" v-if="isOnline" class="asf-error__container">
      <template v-if="isOnline">
        <AsfHeading appearance="h1" class="asf-error__heading">
          <span class="asf-error__title"> {{ generateText(statusCode, 'title') }}</span>

          <template #content-subtitle>
            <span class="asf-error__subtitle">
              <AsfButton v-if="statusCode === 440" class="asf-link asf-link-primary">
                {{ $t('errorPages.sessionExpiredError.click') }}
              </AsfButton>
              {{ generateText(statusCode, 'subtitle') }}
            </span>
          </template>
        </AsfHeading>

        <AsfLink v-if="statusCode !== 440" link="/">
          <AsfButton class="asf-button-primary">
            {{ $t('errorPages.goToHomepage') }}
          </AsfButton>
        </AsfLink>

        <AsfErrorServiceBlock v-if="statusCode !== 440" />
      </template>
      <template v-else>
        <AsfOfflinePage
          :title="$t('errorPages.offlineError.title')"
          :subtitle="$t('errorPages.offlineError.subtitle')"
        />
      </template>
    </AsfWrapper>
  </div>
</template>
<style lang="postcss">
@import '@components/pages/Error/Error.css';
</style>
