import { FocusElement } from './types'

export const focus = {
  mounted(el: FocusElement) {
    el._keyHandler = () => {
      el.focus()
    }

    el.addEventListener('keyup', el._keyHandler)
  },
  unmounted(el: FocusElement) {
    if (el._keyHandler) {
      el.removeEventListener('keyup', el._keyHandler)
    }
  }
}
