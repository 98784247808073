<script setup lang="ts">
import { AsfHeadingProps } from '@ui/types'

withDefaults(defineProps<AsfHeadingProps>(), {
  tag: 'h1'
})
const slots = useSlots()
const hasSubtitle = computed(() => Boolean(slots['content-subtitle']))
</script>
<template>
  <div class="asf-heading">
    <component
      :is="tag || 'h1'"
      v-e2e="'heading-title'"
      class="asf-heading__title"
      :class="appearance && [`m-${appearance}`]"
      :style="styles"
    >
      <!--@slot Heading title.-->
      <slot />
    </component>
    <!--@slot Heading subtitle. Slot content will replace default <div> tag-->
    <p v-if="hasSubtitle" class="asf-heading__subtitle">
      <slot name="content-subtitle" />
    </p>
  </div>
</template>
<style lang="postcss">
@import '@components/atoms/Heading/Heading.css';
</style>
