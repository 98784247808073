/* eslint-disable max-depth */
import {
  AgnosticCategoryLocalized,
  AgnosticLineItem,
  AgnosticOrder,
  AgnosticProduct,
  AgnosticSearchParams,
  AgnosticSearchResult,
  AgnosticSortOptions,
  AgnosticTaxonomy,
  LinkParam,
  UseURL
} from 'shared/types'
import i18nConfig from 'shared/config/i18n'
import type { TypedRouteLocationRaw } from '@typed-router'

const logger = console

const nonFilters = ['page', 'sort', 'q', 'itemsPerPage']

const reduceFilters = (query: Record<string, string | null | (string | null)[]>) => {
  return (prev: Record<string, string[]>, curr: string) => {
    const makeArray = Array.isArray(query[curr]) || nonFilters.includes(curr)

    return {
      ...prev,
      [curr]: makeArray ? query[curr] : [query[curr]]
    } as Record<string, string[]>
  }
}

const getFiltersDataFromUrl = (query: Record<string, string | null | (string | null)[]>, onlyFilters: boolean) => {
  return Object.keys(query)
    .filter((f) => (onlyFilters ? !nonFilters.includes(f) : nonFilters.includes(f)))
    .reduce(reduceFilters(query), {})
}

const applyParamsToURL = (link: string, params: LinkParam[] = []): string => {
  return params.reduce((url, param) => {
    let queryString = ''

    if (Array.isArray(param.value)) {
      queryString = param.value
        .map((value) => `${encodeURIComponent(param.name)}=${encodeURIComponent(value)}`)
        .join('&')
    } else if (Array.isArray(param.key)) {
      queryString = param.key.map((value) => `${encodeURIComponent(param.name)}=${encodeURIComponent(value)}`).join('&')
    } else {
      queryString = `${encodeURIComponent(param.name)}=${encodeURIComponent(param.value)}`
    }

    return url + (url.includes('?') ? '&' : '?') + queryString
  }, link)
}

export const getLocalizedPath = (path: string, locale = i18nConfig.defaultLocale): string => {
  const localeConfig = i18nConfig.locales.find((config) => config.code === locale || config.iso === locale)

  if (!localeConfig) {
    throw new Error(`Locale ${locale} not found`)
  }

  if (i18nConfig.strategy === 'no_prefix' || locale === i18nConfig.defaultLocale) {
    return path
  }

  return `/${localeConfig.code}${path}`
}

export const prependHost = (path: string, locale = i18nConfig.defaultLocale): string => {
  const localeConfig = i18nConfig.locales.find((config) => config.code === locale)

  if (localeConfig) {
    return localeConfig.domain ? new URL(path, localeConfig.domain).toString() : path
  }

  return path
}

const getProductPathFromSlug = (slug: string) => `/p/${(slug || '').replace(/__/gi, '/')}`

const getCategoryPathFromSlug = (slug: string) => `/c/${(slug || '').replace(/__/gi, '/')}`

function getRoutesMapIn() {
  const router = useRouter()
  const routesMap = new Map<string, ReturnType<(typeof router)['getRoutes']>[0]>()
  function fulFillRoutes(routes: ReturnType<(typeof router)['getRoutes']>) {
    for (const _router of routes) {
      if (typeof _router.name === 'string') {
        routesMap.set(_router.name, _router)
        if (_router.children?.length) {
          fulFillRoutes(_router.children as any)
        }
      }
    }
  }
  fulFillRoutes(router.getRoutes())
  return routesMap
}

const getRoutesMap = once(getRoutesMapIn)

export const useURL: UseURL<TypedRouteLocationRaw> = () => {
  const route = useRoute()
  const { $preferences, $i18n: i18n } = useNuxtApp()

  const routesMap = getRoutesMap()

  const getURL = (detectRoute: TypedRouteLocationRaw, locale = i18n.locale.value): string => {
    if ('name' in detectRoute) {
      const nextRoute = routesMap.get(`${detectRoute.name}___${locale}`) ?? routesMap.get(detectRoute.name)

      if (nextRoute) {
        let nextPath = nextRoute.path

        if (detectRoute.name.includes('-')) {
          const [parentPath = ''] = detectRoute.name.split('-')
          const parentRoute = routesMap.get(`${parentPath}___${locale}`) ?? routesMap.get(parentPath)

          if (parentRoute) {
            nextPath = [parentRoute.path, nextPath].join('/')
          }
        }

        if (detectRoute.query) {
          const params = new URLSearchParams(detectRoute.query as any)

          if (params.size) {
            return nextPath + '?' + params.toString()
          } else {
            return nextPath
          }
        } else {
          return nextPath
        }
      } else {
        throw new Error(`Cannot get URL for route ${detectRoute.name}`)
      }
    } else {
      throw new Error(`Missing route name ${detectRoute}`)
    }
  }

  const getCategoryURL = (
    category: AgnosticCategoryLocalized | AgnosticTaxonomy,
    params: LinkParam[] = [],
    locale = i18n.locale.value,
    slugs?: Record<string, string>
  ): string => {
    const isSameLocale = locale === i18n.locale.value
    const alternativeSlug = slugs?.[locale]

    if (isSameLocale) {
      const slug = `${category.slug}`
      const url = getLocalizedPath(getCategoryPathFromSlug(slug), locale)

      return applyParamsToURL(url, params)
    } else if (alternativeSlug) {
      const url = prependHost(getLocalizedPath(getCategoryPathFromSlug(alternativeSlug), locale), locale)

      return applyParamsToURL(url, params)
    } else {
      return getURL({ name: 'index' }, locale)
    }
  }

  const getProductURL = (
    product: AgnosticProduct | AgnosticLineItem,
    params: LinkParam[] = [],
    locale = i18n.locale.value
  ): string => {
    let slug = product.slug

    if (locale) {
      const activeLocale = i18n.locales.value.find((l) => l.code === locale)

      if (activeLocale) {
        slug = product.slugs[activeLocale.iso || ''] ?? slug
      }
    }

    const url = getLocalizedPath(getProductPathFromSlug(slug), locale)

    return applyParamsToURL(url, params)
  }

  const getOrderURL = (order: AgnosticOrder, locale = i18n.locale.value): string =>
    // @ts-ignore
    getURL({ name: 'account-orderdetails', query: { orderId: order.uuid } }, locale)

  const getSlugValue = (path: string): string => {
    let slug = path.replace(/^\//, '')

    for (const locale of i18n.locales.value) {
      if (slug.startsWith(`${locale.code}/`)) {
        slug = slug.replace(`${locale.code}/`, '')
      }
    }
    const pathWithoutEnd = slug.replace(/^(c|p)\//i, '').replace(/\/$/gi, '')

    return pathWithoutEnd.replace(/\//gi, '__')
  }

  const getProductSlug = (path: string) => getSlugValue(path)

  const getDataFromURL = () => {
    const queryToString = (queryParam?: string | null | Array<string | null>): string => {
      return typeof queryParam === 'string' || typeof queryParam === 'number'
        ? queryParam
        : (queryParam && queryParam.join()) || ''
    }

    const slug = getSlugValue(route.path)
    // const categorySlug = Object.keys(params).reduce((prev, curr) => params[curr] || prev, params.slug_1)
    return {
      rootCatSlug: slug,
      categorySlug: undefined,
      page: parseInt(queryToString(route.query.page), 10) || 1,
      sort: queryToString(route.query.sort) || AgnosticSortOptions.newArrivals,
      filters: getFiltersDataFromUrl(route.query, true),
      itemsPerPage: parseInt(queryToString(route.query.itemsPerPage), 10) || $preferences.searchItemsPerPage,
      q: queryToString(route.query.q)
    } as AgnosticSearchParams
  }
  const getHostUrl = (url: string): string => {
    const domain = i18n.localeProperties.value.domain
    try {
      return new URL(url, domain).toString()
    } catch (e) {
      logger.debug(`An error while resolving the url "${url}" against the host "${domain}". Error\n${e}`)

      return domain + url
    }
  }
  const getLinkParams = (searchResult: Partial<AgnosticSearchResult>) => {
    const params: LinkParam[] = []
    if (searchResult.sort) {
      params.push({ name: 'sort', value: searchResult.sort })
    }
    if (searchResult.facets) {
      searchResult.facets.forEach((facet) => {
        facet.values
          ?.filter((value) => value.selected)
          ?.map((v) => {
            params.push({ name: facet.key, value: v.value })
          })
      })
    }
    if (searchResult.pagination) {
      params.push({ name: 'page', value: String(searchResult.pagination.currentPage) })
    }

    return params
  }

  return {
    getHostUrl,
    getURL,
    getCategoryURL,
    getProductURL,
    getOrderURL,
    getProductSlug,
    getDataFromURL,
    getLinkParams
  }
}
